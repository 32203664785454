import React, { useState, useCallback, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import TakeQuestionnaire, {
  TakeQuestionnaireLoading,
} from './TakeQuestionnaire';
import { selectQueryParam, setQueryParam } from '../../../store/router';
import {
  currentSessions,
  answersSheetAndQuestionnaire,
  heartbeat,
  pauseAndSaveDraft,
  complete,
  undoLastChanges,
} from '../../../common/api/forms';
import { userDetails } from '../../../common/api/currentUser';
import { ANSWERS_SHEET_SESSION_KEEP_ALIVE_SECONDS } from '../../../common/constants';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { reconcilingSelector } from '../../../common/utilsClient/selectors';
import {
  forceAutosave,
  syncFormValues,
} from '../../../common/containers/Questionnaire';
import { notifyError } from '../../../utils/notify';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import AnswersSheetSelect from '../../../common/selectors/AnswersSheet';
import QuestionnaireSelect from '../../../common/selectors/Questionnaire';
import AnswersSheetSessionSelect from '../../../common/selectors/AnswersSheetSession';

const getAnswersSheetId = selectQueryParam('takeQuestionnaire');

const Container = () => {
  const answersSheetId = useSelector(getAnswersSheetId);
  const dispatch = useDispatch();

  const { ready: userDetailsReady } = useDDPSubscription(
    userDetails.withParams(),
  );

  const { ready: currentSessionReady } = useDDPSubscription(
    answersSheetId &&
      currentSessions.withParams({
        answersSheetId,
      }),
  );

  const { ready: answersSheetAndQuestionnaireReady } = useDDPSubscription(
    answersSheetId &&
      answersSheetAndQuestionnaire.withParams({
        answersSheetId,
      }),
  );

  const answersSheet = useSelector(
    AnswersSheetSelect.one().whereIdEquals(answersSheetId),
  );

  const isLoggedIn = useSelector(CurrentUserSelect.isLoggedIn());
  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(
      answersSheet && answersSheet.getQuestionnaireId(),
    ),
  );
  const variables = useSelector(
    reconcilingSelector(
      AnswersSheetSessionSelect.one().where({
        userId: CurrentUserSelect.userId(),
        answersSheetId: getAnswersSheetId,
      }),
      (session) => {
        if (session) {
          return session.getEvaluationScopeVariables();
        }
        return {};
      },
    ),
  );

  const handleKeepAlive = useCallback(() => {
    if (isLoggedIn && answersSheetId) {
      dispatch(
        callMethod(
          heartbeat,
          {
            answersSheetId,
          },
          {
            noRetry: true,
            cancelOnReconnect: true,
          },
        ),
      ).catch((err) => {
        if (
          err.error === 'cancel' ||
          err.error === 'api.forms.heartbeat.notLoggedIn' ||
          err.error === 'api.forms.heartbeat.notFound'
        ) {
          return undefined;
        }
        return notifyError()(err);
      });
    }
  }, [dispatch, isLoggedIn, answersSheetId]);

  const handleUndo = useCallback(() => {
    return Promise.resolve()
      .then(() => dispatch(forceAutosave(answersSheetId)))
      .then(() =>
        dispatch(
          callMethod(undoLastChanges, {
            answersSheetId,
          }),
        ),
      )
      .then(({ formValues }) =>
        dispatch(syncFormValues(answersSheetId, formValues)),
      )
      .catch(notifyError());
  }, [dispatch, answersSheetId]);

  const handlePause = useCallback(() => {
    return Promise.resolve()
      .then(() => dispatch(forceAutosave(answersSheetId)))
      .then(() =>
        dispatch(
          callMethod(pauseAndSaveDraft, {
            answersSheetId,
          }),
        ),
      )
      .then(() => dispatch(setQueryParam('takeQuestionnaire', null, true)))
      .catch(notifyError());
  }, [dispatch, answersSheetId]);

  const handleClose = useCallback(() => {
    return Promise.resolve()
      .then(() => dispatch(forceAutosave(answersSheetId)))
      .then(() => dispatch(setQueryParam('takeQuestionnaire', null, true)))
      .catch(notifyError());
  }, [dispatch, answersSheetId]);

  const handleSubmissionError = useCallback((err) => notifyError()(err), []);

  const handleSubmit = useCallback(() => {
    return Promise.resolve().then(
      () =>
        dispatch(
          callMethod(complete, {
            answersSheetId,
          }),
        )
          .then(() => dispatch(setQueryParam('takeQuestionnaire', null, true)))
          .catch((err) => {
            notifyError()(err);
          }),
      () => {
        // ignore error ...
      },
    );
  }, [dispatch, answersSheetId]);

  const handleInitialize = useCallback(() => {
    if (!answersSheetId) {
      return Promise.resolve();
    }
    return dispatch(
      syncFormValues(answersSheetId, null, {
        answersSheetId,
      }),
    );
  }, [dispatch, answersSheetId]);

  useEffect(() => {
    const keepAliveInterval = setInterval(
      handleKeepAlive,
      ANSWERS_SHEET_SESSION_KEEP_ALIVE_SECONDS * 1000,
    );
    return () => {
      clearInterval(keepAliveInterval);
    };
  }, [handleKeepAlive]);

  const [isInitializing, setIsInitializing] = useState(false);

  useEffect(() => {
    let canceled = false;
    if (answersSheetId) {
      setIsInitializing(true);
      handleInitialize().then(() => {
        if (!canceled) {
          setIsInitializing(false);
        }
      });
    }
    return () => {
      canceled = true;
    };
  }, [answersSheetId, handleInitialize]);

  if (!answersSheetId) {
    return null;
  }

  if (
    isInitializing ||
    !userDetailsReady ||
    !currentSessionReady ||
    !answersSheetAndQuestionnaireReady
  ) {
    return <TakeQuestionnaireLoading />;
  }

  return (
    <Suspense fallback={<TakeQuestionnaireLoading />}>
      <TakeQuestionnaire
        answersSheetId={answersSheetId}
        questionnaire={questionnaire}
        variables={variables}
        handleSubmit={handleSubmit}
        handlePause={handlePause}
        handleClose={handleClose}
        handleUndo={handleUndo}
        handleSubmissionError={handleSubmissionError}
      />
    </Suspense>
  );
};

export default Container;

const takeQuestionnaire = (answersSheetId, replaceState) =>
  setQueryParam('takeQuestionnaire', answersSheetId, replaceState);
export { takeQuestionnaire };
