import styled from 'styled-components/macro';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../../common/components/Loading';
import { theme, media } from '../../../common/utilsClient/cssHelpers';
import renderEnvironmentAlert from '../../../utils/renderEnvironmentAlert';
import TakeQuestionnaire from '../../dialogs/TakeQuestionnaire';
import Drawer from '../Drawer';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const InnerLayout = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${theme('color.background')};
  overflow-x: hidden;
  height: 100%;
`;

const StyledContent = styled.main`
  flex: auto;
  min-height: 0;
  overflow-y: auto;
  padding: ${theme('space.3')};
`;

const OuterLayout = styled.section`
  height: 100%;
  display: flex;

  ${(props) =>
    props.hasNavigation &&
    media.lessThan('mobile')`
    flex-direction: column;
  `}
`;

const MasterLayout = ({ children }) => {
  return (
    <>
      <OuterLayout hasNavigation>
        <Drawer />
        <InnerLayout>
          <Suspense fallback={<Loading />}>
            {renderEnvironmentAlert()}
            {/* {renderHeader()} */}
          </Suspense>
          <StyledContent>
            <Suspense fallback={<Loading />}>{children}</Suspense>
          </StyledContent>
        </InnerLayout>
      </OuterLayout>
      <Suspense fallback={<Loading />}>
        <TakeQuestionnaire />
      </Suspense>
    </>
  );
};

MasterLayout.propTypes = propTypes;

export default MasterLayout;
