/* eslint-disable react/prop-types */
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import minOf from 'lodash/min';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import { StickyNote } from 'styled-icons/fa-regular';
import { CheckBox, CheckBoxOutlineBlank } from 'styled-icons/material';
import { PhoneAndroid, Email } from 'styled-icons/material-outlined';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../common/utilsClient/cssHelpers';
import { isNilValue, parseValueExpr } from '../../../../common/utils/question';
import Text from '../../../../common/components/base/Text';
import Cluster from '../../../../common/components/primitives/Cluster';
import Stack from '../../../../common/components/primitives/Stack';
import Badge from '../../../../common/components/Badge';
import Tooltip from '../../../../common/components/Tooltip';
import Button, { ButtonLink } from '../../../../common/components/Button';
import Table from '../../../../common/components/Table';
import Icon from '../../../../common/components/Icon';
import {
  VARIABLE_ID__PARTICIPATION_EMR_SYNC,
  VARIABLE_ID__GENDER,
  VARIABLE_ID__GENDER_SINGAPORE,
  VARIABLE_ID__BIRTH_DATE,
  VARIABLE_ID__BIRTH_YEAR,
  VARIABLE_ID__PARTICIPATION_CATEGORY,
  VARIABLE_ID__NAME,
  VARIABLE_ID__NAME_FIRST,
  VARIABLE_ID__NAME_LAST,
  VARIABLE_ID__NATIONAL_ID,
  VARIABLE_ID__PARTICIPATION_BED_NUMBER,
  VARIABLE_ID__PHONE_MOBILE,
  VARIABLE_ID__EMAIL_PERSONAL,
  SIGNED_NOTE_TYPE__RESOLVED,
} from '../../../../common/constants';
import branding from '../../../../utils/branding';
import copyToClipboard from '../../../../common/utilsClient/copyToClipboard';
import message from '../../../../common/utilsClient/message';
import {
  openAnswersSheetDialog,
  openPreviewPatientResponseDialog,
} from '../../actions';
import DashboardPatientModal from './DashboardPatientModal';
import DashboardResolveModal from './DashboardResolveModal';
import DashboardVariableItem from './DashboardVariableItem';
import { labels } from './helpers';

const StyledTable = styled(Table)`
  th {
    font-size: ${theme('font.size.small')};
  }

  th,
  td {
    white-space: nowrap;
  }

  th {
    text-align: inline-start;
  }

  /*  Reduce space between columns with icons */

  thead > tr > th,
  tbody > tr > td {
    &:first-child {
      /* padding-right: 0; */
    }

    &:nth-child(2) {
      /* padding-left: 0; */
    }
  }
`;

const Status = ({ type, value }) => {
  return (
    <Text.Paragraph
      type={type}
      style={{
        maxWidth: 150,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {value ? (
        <Text.Span
          data-testid="status"
          importance={type === 'warning' || type === 'danger' ? 'high' : null}
        >
          {value}
        </Text.Span>
      ) : (
        <Text.Span data-testid="status">&mdash;</Text.Span>
      )}
    </Text.Paragraph>
  );
};

const getVariable = (variables, variableId) => {
  if (isArray(variableId)) {
    return compact(map(variableId, (id) => getVariable(variables, id))).join(
      ' ',
    );
  }
  return variables && variables[variableId];
};

const getNotesCount = (record) => {
  let answersSheet;

  if (record.answersSheetId) {
    answersSheet = find(
      record.answersSheets,
      (item) => item.id === record.answersSheetId,
    );
  } else {
    answersSheet = record;
  }

  const signedNotes = answersSheet && answersSheet.signedNotes;

  return signedNotes && signedNotes.length;
};

const getRecordResolved = (record) => {
  if (record.signedNotes) {
    return some(record.signedNotes, {
      type: SIGNED_NOTE_TYPE__RESOLVED,
    });
  }

  return record.resolved;
};

const getStatus = (ids, values, thresholds) => {
  const idToIndex = {};
  if (!thresholds) {
    return null;
  }
  forEach(ids, (variableId, index) => {
    idToIndex[index] = variableId;
  });
  const statusCodes = map(ids, (variableId, index) => {
    const variableValue = values[index];
    if (isNil(variableValue)) {
      return 4;
    }
    for (let i = 0; i < thresholds.length; i += 1) {
      const { id, max, min, type, value } = thresholds[i];
      let statusCode;
      switch (type) {
        case 'danger':
          statusCode = 1;
          break;
        case 'warning':
          statusCode = 2;
          break;
        case 'ok':
          statusCode = 3;
          break;
        default:
          statusCode = 4;
      }

      if (!id || id === variableId) {
        if (!isNil(value)) {
          if (value === variableValue) {
            return statusCode;
          }
        } else if (
          (isNil(max) || variableValue <= max) &&
          (isNil(min) || variableValue >= min)
        ) {
          return statusCode;
        }
      }
    }
    return 4;
  });
  const statusCode = minOf(statusCodes);
  switch (statusCode) {
    case 1:
      return 'danger';
    case 2:
      return 'warning';
    case 3:
      return 'success';
    default:
      return null;
  }
};

const DashboardTable = ({
  columns,
  dataSource,
  pagination,
  loading,
  hideColumns,
}) => {
  const { t } = useTranslation();

  const [isResolveModalVisible, setIsResolveModalVisible] = useState(false);
  const [isPatientModalVisible, setIsPatientModalVisible] = useState(false);
  const [dataSourceHistorical, setDataSourceHistorical] = useState([]);
  const [selectedAnswersSheetId, setSelectedAnswersSheetId] = useState('');
  const [selectedPatientName, setSelectedPatientName] = useState('');

  const handleResolveModalOpen = (answersSheetId, patientName) => {
    setIsResolveModalVisible(true);
    setSelectedAnswersSheetId(answersSheetId);
    setSelectedPatientName(patientName);
  };
  const handleResolveModalClose = () => {
    setIsResolveModalVisible(false);
    setSelectedAnswersSheetId(null);
    setSelectedPatientName(null);
  };
  const handlePatientModalOpen = (record) => {
    if (!record) {
      return;
    }

    setIsPatientModalVisible(true);
    setDataSourceHistorical(
      map(record.answersSheets, (answersSheet) => ({
        ...answersSheet,
        variables: record.variables,
        answersSheetId: answersSheet.id,
        projectId: record.projectId,
      })),
    );
  };
  const handlePatientModalClose = () => setIsPatientModalVisible(false);

  const dispatch = useDispatch();
  const onNotesOpen = (answersSheetId) =>
    dispatch(
      openAnswersSheetDialog({
        answersSheetId,
      }),
    );

  let tableColumns = [
    {
      key: 'resolve',
      fixed: 'left',
      width: 50,
      render: (_, record) =>
        record.answersSheetId || record.id ? (
          <Button
            type="link"
            icon={
              getRecordResolved(record) ? (
                <CheckBox />
              ) : (
                <CheckBoxOutlineBlank />
              )
            }
            onClick={() =>
              handleResolveModalOpen(
                record.answersSheetId || record.id,
                getVariable(record.variables, VARIABLE_ID__NAME) ||
                  getVariable(record.variables, [
                    VARIABLE_ID__NAME_FIRST,
                    VARIABLE_ID__NAME_LAST,
                  ]),
              )
            }
            disabled={getRecordResolved(record)}
          />
        ) : null,
    },
    {
      title: t('note', {
        count: 0,
      }),
      key: 'notes',
      fixed: 'left',
      width: 75,
      render: (_, record) =>
        record.answersSheetId || record.id ? (
          <Badge
            type="info"
            count={getNotesCount(record)}
            offsetX={-8}
            offsetY={8}
          >
            <Button
              type="link"
              icon={<StickyNote />}
              onClick={() => onNotesOpen(record.answersSheetId || record.id)}
            />
          </Badge>
        ) : null,
    },
    {
      title: t('recipient', {
        context: branding,
      }),
      width: 150,
      fixed: 'left',
      key: 'patientColumn1',
      render: (_, record) => {
        const name =
          getVariable(record.variables, VARIABLE_ID__NAME) ||
          getVariable(record.variables, [
            VARIABLE_ID__NAME_FIRST,
            VARIABLE_ID__NAME_LAST,
          ]);

        return (
          <Stack space={0}>
            <Tooltip title={name}>
              <ButtonLink onClick={() => handlePatientModalOpen(record)} block>
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {name}
                </div>
              </ButtonLink>
            </Tooltip>
            <DashboardVariableItem
              size="small"
              label={labels[VARIABLE_ID__PARTICIPATION_EMR_SYNC]}
              value={getVariable(
                record.variables,
                VARIABLE_ID__PARTICIPATION_EMR_SYNC,
              )}
            />
            <Cluster space={0} wrap="nowrap">
              <DashboardVariableItem
                size="small"
                value={
                  <Icon
                    type={
                      getVariable(record.variables, VARIABLE_ID__GENDER) ||
                      getVariable(
                        record.variables,
                        VARIABLE_ID__GENDER_SINGAPORE,
                      )
                    }
                  />
                }
              />
              <DashboardVariableItem
                size="small"
                label={labels[VARIABLE_ID__BIRTH_DATE]}
                value={
                  getVariable(record.variables, VARIABLE_ID__BIRTH_DATE) ||
                  getVariable(record.variables, VARIABLE_ID__BIRTH_YEAR)
                }
              />
            </Cluster>
          </Stack>
        );
      },
    },
    {
      title: t('contact'),
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => {
        const mobile = getVariable(record.variables, VARIABLE_ID__PHONE_MOBILE);
        const email = getVariable(
          record.variables,
          VARIABLE_ID__EMAIL_PERSONAL,
        );
        // TODO: We need to translate the message on a "global" level not just for this dashboard use case
        const handleOnCopyMobile = () =>
          copyToClipboard(mobile, () =>
            message.success(t('copiedToClipboard')),
          );
        const handleOnCopyEmail = () =>
          copyToClipboard(email, () => message.success(t('copiedToClipboard')));

        return (
          <Stack space={0}>
            <div>
              {mobile && (
                <Tooltip title={t('copyNumber')}>
                  <ButtonLink
                    icon={<PhoneAndroid />}
                    onClick={handleOnCopyMobile}
                    decoration="none"
                  >
                    {getVariable(record.variables, VARIABLE_ID__PHONE_MOBILE)}
                  </ButtonLink>
                </Tooltip>
              )}
            </div>
            <div>
              {email && (
                <Tooltip title={t('copyEmail')}>
                  <ButtonLink
                    icon={<Email />}
                    onClick={handleOnCopyEmail}
                    decoration="none"
                  >
                    {getVariable(record.variables, VARIABLE_ID__EMAIL_PERSONAL)}
                  </ButtonLink>
                </Tooltip>
              )}
            </div>
          </Stack>
        );
      },
    },
    {
      title: t('forms:identifier.label'),
      key: 'identifier',
      render: (_, record) => (
        <Stack space={0}>
          <Text.Paragraph>
            {getVariable(record.variables, VARIABLE_ID__NATIONAL_ID)}
          </Text.Paragraph>
          <DashboardVariableItem
            size="small"
            label={labels[VARIABLE_ID__PARTICIPATION_BED_NUMBER]}
            value={getVariable(
              record.variables,
              VARIABLE_ID__PARTICIPATION_BED_NUMBER,
            )}
          />
          <DashboardVariableItem
            size="small"
            label={labels[VARIABLE_ID__PARTICIPATION_CATEGORY]}
            value={getVariable(
              record.variables,
              VARIABLE_ID__PARTICIPATION_CATEGORY,
            )}
          />
        </Stack>
      ),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      // sorter: () => true,
      render: (_, record) =>
        record.completedAt ? (
          <Stack space={0}>
            <Text.Paragraph>
              {moment(record.completedAt).format('DD/MM')}
            </Text.Paragraph>
            <Text.Paragraph>
              {moment(record.completedAt).format('HH:mm')}
            </Text.Paragraph>
          </Stack>
        ) : (
          <Text.Span>&mdash;</Text.Span>
        ),
    },
    {
      title: t('missed'),
      dataIndex: 'missed',
      key: 'numberOfMissed',
      align: 'right',
      render: (_, record) => (record.numberOfMissed || 0).toString(),
    },
  ];

  forEach(
    columns,
    ({ title, id, variableType, valueType, precision, thresholds }) => {
      tableColumns.push({
        title,
        dataIndex: id.join('_'),
        key: id.join('_'),
        align: 'right',
        // sorter: () => true,
        render: (_, record) => {
          const values = map(id, (variableId) => {
            if (variableType === 'questionnaire') {
              let value = getVariable(
                record.questionnaireVariables,
                variableId,
              );
              if (isNilValue(value)) {
                return null;
              }
              if (valueType === 'number' && typeof value !== 'number') {
                // NOTE: Handle edge case where Q. value does not necessarily need to
                //       be explicitly a number, but there's a chance it can be converted
                //       to a number.
                value = parseValueExpr(value);
                if (isNaN(value)) {
                  return null;
                }
              }
              return value;
            }
            return null;
          });
          const valuesAsStrings = map(
            filter(values, (val) => !isNil(val)),
            (value) => {
              // NOTE: We know that value will be a number here because of the conversion
              //       and filtering above, but better check than sorry.
              if (
                valueType === 'number' &&
                typeof value === 'number' &&
                typeof precision === 'number'
              ) {
                return value.toFixed(precision);
              }
              return `${value}`;
            },
          );
          const status = getStatus(id, values, thresholds);
          return <Status type={status} value={valuesAsStrings.join('/')} />;
        },
      });
    },
  );
  if (Array.isArray(hideColumns) && hideColumns.length > 0) {
    tableColumns = tableColumns.filter((el) => {
      if (el.key) {
        return !hideColumns.some(
          (item) => item.toLowerCase().trim() === el.key.toLowerCase().trim(),
        );
      }
      return true;
    });
  }
  tableColumns.push({
    title: '',
    key: 'more',
    render: (_, record) => {
      const { answersSheetId, projectId } = record;

      const handleOnShowMore = () =>
        dispatch(
          openPreviewPatientResponseDialog({
            answersSheetId,
            projectId,
          }),
        );

      return (
        <Button
          type="primary"
          onClick={handleOnShowMore}
          disabled={!answersSheetId && !projectId}
        >
          {t('showMore')}
        </Button>
      );
    },
  });
  return (
    <Stack space={2}>
      <DashboardResolveModal
        answersSheetId={selectedAnswersSheetId}
        patientName={selectedPatientName}
        visible={isResolveModalVisible}
        onCancel={handleResolveModalClose}
      />
      <DashboardPatientModal
        dataSource={dataSourceHistorical}
        tableColumns={tableColumns}
        visible={isPatientModalVisible}
        onCancel={handlePatientModalClose}
        getVariable={getVariable}
      />
      <StyledTable
        size="small"
        dataSource={dataSource}
        columns={tableColumns}
        loading={loading}
        pagination={pagination}
        scroll={{
          x: true,
        }}
        isNewStyling
      />
    </Stack>
  );
};

DashboardTable.propTypes = {};

DashboardTable.defaultProps = {};

export default DashboardTable;
